import { render, staticRenderFns } from "./EnglishFormUserReport.vue?vue&type=template&id=74ee58f4"
import script from "./EnglishFormUserReport.vue?vue&type=script&lang=js"
export * from "./EnglishFormUserReport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/CoreProductivity.Backoffice.Mobile/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74ee58f4')) {
      api.createRecord('74ee58f4', component.options)
    } else {
      api.reload('74ee58f4', component.options)
    }
    module.hot.accept("./EnglishFormUserReport.vue?vue&type=template&id=74ee58f4", function () {
      api.rerender('74ee58f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/form/EnglishFormUserReport.vue"
export default component.exports